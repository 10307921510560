var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(!_vm.data.properties.hideLabel)?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.data &&
        _vm.data.validations &&
        _vm.data.validations.required &&
        _vm.hasLabel &&
        !_vm.isView
      ),expression:"\n        data &&\n        data.validations &&\n        data.validations.required &&\n        hasLabel &&\n        !isView\n      "}],staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.properties.filed_content != 'Hide' && !_vm.visibility),expression:"data.properties.filed_content != 'Hide' && !visibility"}],staticClass:"rectangular-block",style:(_vm.getIsMobile ? '' : _vm.getStyle)})])
}
var staticRenderFns = []

export { render, staticRenderFns }